/** Custom Material styles */
.cdk-overlay-pane {
  z-index: 1000 !important;
}

.title-icon-suffix {
  display: flex;
  align-items: center;

  h1, h2, h3, h4, h5 {
    display: flex;
  }

  .mat-icon {
    font-size: 14px;
    height: 14px;
    width: 14px;
  }
}

.secondary-button {
  outline: 2px solid #F0CD65 !important;
}

.secondary-icon-button.mat-mdc-icon-button  {
  background-color: white !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--invalid .mat-mdc-notch-piece {
  border: 0 !important;
}

.mdc-text-field--outlined {
  background-color: white !important;
  border-radius: 20px !important;
}

.mdc-text-field--filled {
  border-bottom-left-radius: var(--mdc-filled-text-field-container-shape) !important;
  border-bottom-right-radius: var(--mdc-filled-text-field-container-shape) !important;
}

.mat-mdc-form-field-icon-prefix, .mat-mdc-form-field-icon-suffix {
  align-self: flex-start !important;
  margin-top: 8px !important;
}

.mat-mdc-form-field-icon-prefix>.mat-icon {
  padding: 0 0 0 24px !important;
}

.mat-mdc-form-field-icon-suffix {
  display: flex;
}

.mat-mdc-form-field-icon-suffix>.mat-icon {
  padding: 0 8px 0 0 !important;
  font-size: medium;
  color: rgb(128,128,128);
  align-content: center;
}

.mat-mdc-form-field-icon-suffix>.mat-icon:hover {
  color: #000000;
}

.mat-mdc-form-field-has-icon-prefix .mat-mdc-text-field-wrapper {
  --mat-mdc-form-field-label-offset-x: -24px !important;
}

// -------- stepper ----------
.mat-horizontal-content-container {
  background-color: #F2D275;
  border-radius: 25px;
  padding: 24px !important;
}

.mat-horizontal-stepper-header-container {
    padding-bottom: 16px !important;
}

.mat-horizontal-stepper-header {
  flex-direction: column;
  padding: 8px !important;
}

.mat-step-text-label {
  color: black;
  text-wrap: wrap;
  text-align: center;
}

.mat-step-label-selected > .mat-step-text-label {
  color: white !important;
}

.mat-step-icon {
  color: black !important;
  border: solid 2px black;
  margin-bottom: 8px !important;
  background-color: transparent !important;
}

.mat-step-icon-selected {
  color: white !important;
  border: solid 2px white;
}

.mat-stepper-horizontal-line {
  border-top: solid 2px black !important;
  margin: -32px 0 0 0 !important;
}

.mat-mdc-outlined-button:not(:disabled) {
  color: black !important;
}

mat-card {
  margin-bottom: var(--gutter);
}

mat-card-header + mat-card-content {
  margin-top: var(--gutter);
}

.form-field-full {
  .mat-mdc-form-field {
    width: 100%;
  }
}

.mat-expansion-panel-header {
  height: auto !important;
}

.mat-mdc-chip {
  margin: 12px !important;
}

.mdc-list-item.mdc-list-item--with-trailing-meta {
   .mdc-list-item__end {
    font-size: inherit;
  }
}
